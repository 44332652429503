<template>
  <engaging-stand-out-block :title="title" v-if="!userIsSubscribedState" align-vertical="center">
    <subscription-plans-link slot="button" class="fat" :label="action"></subscription-plans-link>
  </engaging-stand-out-block>
</template>

<script>
import EngagingStandOutBlock from '@common/elements/layouts/EngagingStandOutBlock';
import {mapGetters} from 'vuex';

import SubscriptionPlansLink from '@/components/common/buttons/SubscriptionPlansLink';

export default {
  props: {
    title: {type: String, default: 'Find records of your Chinese ancestors and their journey overseas!'},
    action: {type: String, default: 'Compare Plans'},
  },
  computed: {
    ...mapGetters(['userIsSubscribedState']),
  },
  name: 'ctaBlockComparePlans',
  components: {SubscriptionPlansLink, EngagingStandOutBlock},
};
</script>

<style lang="scss" scoped>
.engaging-stand-out-block {
  margin-top: 50px;
}
</style>
