<template>
  <div class="section-related-records">
    <h5>Chinese Family Records in {{ pinyin }}</h5>
    <p>Search our database of overseas Chinese records, including aggregated data from databases around the world.</p>

    <div class="records-container">
      <stand-out-block-image :imgSrc="$getAsset('/assets/services/immig-records.png')">
        <template slot="description">
          <div v-if="mentionsLoading">Loading...</div>
          <router-link v-else-if="mentionsCount" :to="searchMentionsRoute">
            Browse {{ mentionsCountDisplay }} Chinese family records in {{ pinyin }}
          </router-link>
          <div v-else class="link-container">
            <div class="supplemental">No records for {{ pinyin }}.</div>
            <router-link :to="allRecordsRoute">Browse all indexed records.</router-link>
          </div>
        </template>
      </stand-out-block-image>
      <stand-out-block-image :imgSrc="$getAsset('/assets/services/association-page.png')">
        <template slot="description">
          <div v-if="sourcesLoading">Loading...</div>
          <router-link v-else-if="sourcesCount" :to="searchSourcesRoute">
            Browse {{ sourcesCountDisplay }} Chinese family records sources in {{ pinyin }}
          </router-link>
          <div v-else class="link-container">
            <div class="supplemental">No sources for {{ pinyin }}.</div>
            <router-link :to="allSourcesRoute">Browse all records sources.</router-link>
          </div>
        </template>
      </stand-out-block-image>
    </div>
  </div>
</template>

<script>
import StandOutBlockImage from '@common/elements/layouts/StandOutBlockImage';
import {TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';

export default {
  props: {
    pinyin: String,
    placeId: Number,
  },
  created() {
    this.$store
      .dispatch('searchSourcesInBackgroundAction', {auto_place_id: this.$route.params.placeId, only_count: true})
      .then(response => {
        this.sourcesCount = response.meta.total_count;
        this.sourcesLoading = false;
      });
    this.$store
      .dispatch('searchAllRecordsInBackgroundAction', {auto_place_id: this.$route.params.placeId, only_count: true})
      .then(response => {
        this.mentionsCount = response.meta.total_count;
        this.mentionsLoading = false;
      });
  },
  data() {
    return {
      sourcesLoading: true,
      mentionsLoading: true,
      mentionsCount: 0,
      sourcesCount: 0,
    };
  },
  computed: {
    searchMentionsRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS, auto_place_id: this.placeId}};
    },
    searchSourcesRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_SOURCES, auto_place_id: this.placeId}};
    },
    allRecordsRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS}};
    },
    allSourcesRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_SOURCES, results: 'true'}};
    },
    mentionsCountDisplay() {
      return this.mentionsCount && this.mentionsCount.toLocaleString('en');
    },
    sourcesCountDisplay() {
      return this.sourcesCount && this.sourcesCount.toLocaleString('en');
    },
  },
  name: 'SectionRelatedRecords',
  components: {StandOutBlockImage},
};
</script>

<style lang="scss" scoped>
.section-related-records {
  .records-container {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;

    .stand-out-block-image {
      width: 48.5%;

      &::v-deep a {
        font-weight: bold;
      }
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .stand-out-block-image {
        width: 100%;

        &::v-deep img {
          height: 200px;
          width: 100%;
          object-fit: cover;
        }

        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
