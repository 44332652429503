<template>
  <div class="details-item">
    <mcr-wiki-glossary-link v-if="wikiId" class="help-text label" :naturalId="wikiId" :is-help-text="true">
      {{ label }}
    </mcr-wiki-glossary-link>
    <div class="label" v-else>{{ label }}</div>
    <div class="value">
      <slot><div class="unknown-value">Unknown</div></slot>
    </div>
  </div>
</template>

<script>
import mcrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';

export default {
  props: {
    label: String,
    wikiId: {type: String, optional: true},
  },
  components: {mcrWikiGlossaryLink},
};
</script>

<style lang="scss" scoped>
.details-item {
  display: flex;
  border-top: 1px solid $divider-line-light;

  &:first-child {
    margin-top: 16px;
  }
  &:last-child {
    border-bottom: 1px solid $divider-line-light;
  }

  > * {
    padding: 8px 0;
  }

  .label {
    margin-right: 6px;
    opacity: 0.7;
    width: 155px;
    padding-left: 12px;
    background-color: $background-label;
    flex-shrink: 0;
    @media only screen and (max-width: $breakpoint-mobile-se) {
      width: 100px;
    }
  }

  .mcr-wiki-glossary-link.help-text {
    color: $text-color;
  }

  .value {
    padding-left: 8px;
    word-break: break-word;
    white-space: pre-line;
    .unknown-value {
      color: $supplemental-text-color;
    }
  }

  &::v-deep .name:not(:last-child)::after {
    content: ', ';
  }
}
</style>
