<template>
  <div class="migration-destination-details-page">
    <div class="readable_content">
      <h4>The Chinese Community in {{ placeState.pinyin }}</h4>
      <div
        class="sub-section"
        v-if="placeState.migration_meta.description"
        v-prerender-if="'migration_meta.description'"
        v-html="placeState.migration_meta.description"
      ></div>
      <div
        class="sub-section"
        v-if="placeState.migration_meta.est_population"
        v-prerender-if="'migration_meta.est_population'"
      >
        <span><strong>Estimated Population: </strong></span>
        <span>{{ placeState.migration_meta.est_population.toLocaleString('en') }}</span>
      </div>
      <section-related
        v-if="placeState.dialects && placeState.dialects.length"
        v-prerender-if="'dialects'"
        class="sub-section"
        title="Dialect(s) Spoken: "
        :items="placeState.dialects"
        prerender-list-name="dialects"
        :is-template="isTemplate"
      ></section-related>
      <section-wiki-articles class="sub-section" :place-id="placeState.id" v-if="!isTemplate"></section-wiki-articles>

      <cta-block-compare-plans class="section"></cta-block-compare-plans>

      <section-google-map
        v-if="showMap"
        class="section"
        :address="placeState.full_address_en"
        :name="placeState.name"
        :level="placeState.level"
        :coordinates="placeState.location"
        @set-marker="onMarkerSet"
      ></section-google-map>
      <div class="section" v-else></div>

      <section-address :items="placeState.ancestors" :full-address-en="placeState.full_address_en"></section-address>

      <section-related-records
        v-if="!isTemplate"
        :pinyin="placeState.pinyin"
        :place-id="placeState.id"
        class="section"
      ></section-related-records>

      <ancestral-locations-section
        class="section"
        :pinyin="placeState.pinyin"
        :place-id="placeState.id"
        :description="placeState.migration_meta.origin_description"
        :description-prerender-name="'migration_meta.origin_description'"
        :is-template="isTemplate"
      ></ancestral-locations-section>
      <popular-surnames-section class="section" :pinyin="placeState.pinyin"></popular-surnames-section>
    </div>
  </div>
</template>

<script>
import SectionRelated from '@common/elements/layouts/SectionRelated';
import AncestralLocationsSection from '@common/pages/placeDetails/AncestralLocationsSection';
import PopularSurnamesSection from '@common/pages/placeDetails/PopularSurnamesSection';
import SectionGoogleMap from '@common/pages/placeDetails/SectionGoogleMap';
import SectionWikiArticles from '@common/pages/placeDetails/SectionWikiArticles';
import SectionAddress from '@common/pages/placeDetails/migration/SectionAddress';
import SectionRelatedRecords from '@common/pages/placeDetails/migration/SectionRelatedRecords';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';

import migrationDestinationData from '../prerender/placeData';
import placeSearchMeta from '../prerender/placeSearchMeta';
import spellingsListData from '../prerender/spellingsListData';

export default {
  metaInfo() {
    const name = this.placeState ? this.placeState.pinyin : '';
    return {
      title: `The Chinese in ${name} Facts and Origins`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Discover the history of Chinese immigration to ${name}, and where the Chinese in ${name} come from. Search records of Chinese in ${name}. Browse popular surnames of Chinese in ${name}.`,
        },
      ],
    };
  },
  created() {
    if (this.$route.meta.isPrerenderTemplate) {
      this.isTemplate = true;
      this.$store.commit('setPlaceState', migrationDestinationData);
      this.$store.commit('setSpellingSearchState', spellingsListData);
      this.$store.commit('setPlaceSearchMetaState', placeSearchMeta);
      return;
    }
    if (window.__PRERENDERED_STATE__) {
      this.$store.commit('setPlaceState', window.__PRERENDERED_STATE__.place);
      this.$store.commit('setSpellingSearchState', window.__PRERENDERED_STATE__.spellings);
      this.$store.commit('setPlaceSearchMetaState', window.__PRERENDERED_STATE__.place_search_meta);
      window.__PRERENDERED_STATE__ = null;
      return;
    }
    // set showMap true in 1 second
    setTimeout(() => {
      this.showMap = true;
    }, 1000);
    this.init();
  },
  data() {
    return {
      isTemplate: false,
      showMap: false,
    };
  },
  computed: {
    ...mapGetters(['placeState']),
  },
  methods: {
    init() {
      this.$store.dispatch('searchSpellingsAction', {place_id: this.$route.params.placeId, limit: 28});
      this.$store.dispatch('searchPlacesAction', {migration_place_id: this.$route.params.placeId});
    },
    onMarkerSet({location}) {
      if (isEmpty(this.placeState.location)) {
        this.$store.commit('setPlaceLocationState', location);
        this.$store.dispatch('savePlaceLocationAction', {id: this.placeState.id, location});
      }
    },
  },
  components: {
    CtaBlockComparePlans,
    AncestralLocationsSection,
    PopularSurnamesSection,
    SectionRelated,
    SectionAddress,
    SectionGoogleMap,
    SectionWikiArticles,
    SectionRelatedRecords,
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin-top: 50px;
}
.sub-section {
  margin-top: 18px;
}
</style>
