<template>
  <div class="search-villages-result-item search-result-item">
    <h4>
      <router-link :to="toRoute" class="visitable"> {{ item.address_en }} {{ item.name }} </router-link>
    </h4>

    <div class="supplemental alternative" v-if="alsoKnownAs" v-html="alsoKnownAs"></div>

    <div class="addresses">
      <div>{{ item.full_address_en }}</div>
      <div>{{ item.full_address_ch }}</div>
    </div>

    <div v-if="clans" class="clans">
      <span class="supplemental">Prominent Clans: </span>
      <span>{{ clans }}</span>
    </div>
  </div>
</template>

<script>
import {getAliasMatchHighlighted} from '@common/utils/utils.names';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    item: Object,
  },
  computed: {
    toRoute() {
      return getPlaceDetailRoute(this.item.id, this.item.pinyin);
    },
    alsoKnownAsMatches() {
      if (!this.$route.query.q) {
        return;
      }
      const matches = getAliasMatchHighlighted(this.item.aliases, this.$route.query.q, [
        this.item.pinyin,
        this.item.name,
      ]);
      return matches.join(', ');
    },
    alsoKnownAs() {
      const matches = this.alsoKnownAsMatches;
      return matches ? `Also known as "${matches}"` : '';
    },
    clans() {
      return this.item.clans
        .map(clan => {
          return `${clan.name_hant || clan.name_ch} ${clan.pinyin}`;
        })
        .join(', ');
    },
  },
  name: 'SearchVillagesResultItem',
};
</script>

<style lang="scss" scoped>
.alternative {
  font-size: 24px;
  margin-top: 10px;
}
.addresses,
.clans {
  margin-top: 10px;
}
@media only screen and (max-width: $breakpoint-mobile) {
  h4,
  .alternative {
    word-break: break-word;
  }
}
</style>
