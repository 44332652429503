<script>
import {networks} from '@/base/utils/utils.social-sharing';

// https://github.com/nicolasbeauvais/vue-social-sharing

let $window = typeof window !== 'undefined' ? window : null;

export default {
  props: {
    network: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    /**
     * Properties to configure the popup window.
     */
    popup: {
      type: Object,
      default: () => ({
        width: 626,
        height: 436,
      }),
    },
  },

  data() {
    return {
      popupTop: 0,
      popupLeft: 0,
      popupWindow: undefined,
      popupInterval: null,
    };
  },

  computed: {
    key() {
      return this.network.toLowerCase();
    },
    networkConfig() {
      return networks[this.key];
    },
    shareLink() {
      return this.networkConfig.url
        .replace(/@u/g, encodeURIComponent(this.url))
        .replace(/@t/g, encodeURIComponent(this.title));
    },
  },
  render: function (createElement) {
    if (!this.networkConfig) {
      throw new Error('Network ' + this.key + ' does not exist');
    }

    const node = {
      class: 'social-share-button social-share-button-' + this.key,
      style: {backgroundColor: this.networkConfig.color, color: this.networkConfig.textColor || '#FFF'},
      on: {
        click: () => this[this.shareLink.substring(0, 4) === 'http' ? 'share' : 'touch'](),
      },
    };
    node.attrs = {href: 'javascript:void(0)'};
    // node.style.backgroundColor = this.networkConfig.color;
    const tag = 'a';
    return createElement(tag, node, [
      createElement(this.networkConfig.icon, {props: {size: 15}}),
      createElement('span', this.networkConfig.name),
    ]);
  },
  methods: {
    /**
     * Center the popup on multi-screens
     * http://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen/32261263
     */
    resizePopup() {
      const width = $window.innerWidth || document.documentElement.clientWidth || $window.screenX;
      const height = $window.innerHeight || document.documentElement.clientHeight || $window.screenY;
      const systemZoom = width / $window.screen.availWidth;

      this.popupLeft =
        (width - this.popup.width) / 2 / systemZoom +
        ($window.screenLeft !== undefined ? $window.screenLeft : $window.screenX);
      this.popupTop =
        (height - this.popup.height) / 2 / systemZoom +
        ($window.screenTop !== undefined ? $window.screenTop : $window.screenY);
    },
    share() {
      this.resizePopup();

      // If a popup window already exist, we close it and trigger a change event.
      if (this.popupWindow && this.popupInterval) {
        clearInterval(this.popupInterval);

        // Force close (for Facebook)
        this.popupWindow.close();

        this.emit('change');
      }

      this.popupWindow = $window.open(
        this.shareLink,
        'sharer-' + this.key,
        ',height=' +
          this.popup.height +
          ',width=' +
          this.popup.width +
          ',left=' +
          this.popupLeft +
          ',top=' +
          this.popupTop +
          ',screenX=' +
          this.popupLeft +
          ',screenY=' +
          this.popupTop
      );

      // If popup are prevented (AdBlocker, Mobile App context..), popup.window stays undefined and we can't display it
      if (!this.popupWindow) return;

      this.popupWindow.focus();

      // Create an interval to detect popup closing event
      this.popupInterval = setInterval(() => {
        if (!this.popupWindow || this.popupWindow.closed) {
          clearInterval(this.popupInterval);
          this.popupWindow = null;
          this.emit('close');
        }
      }, 500);

      this.emit('open');
    },
    touch() {
      window.open(this.shareLink, '_blank');
      this.emit('open');
    },
    emit(name) {
      this.$emit(name, {key: this.key, url: this.url});
    },
  },
};
</script>
<style lang="scss">
.social-share-button {
  background-color: #333;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 5px 10px 5px 5px;

  .material-design-icon {
    margin: 0 0 3px 2px;
  }

  span {
    margin-left: 5px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  }
}
</style>
