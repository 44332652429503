<template>
  <div class="place-input">
    <label class="label" :class="[labelClasses, {disabled: disabled}]" :for="id" v-if="label">{{ label }}</label>
    <multiselect
      :value="value"
      :id="id"
      ref="place-input"
      :placeholder="placeholder"
      :searchable="true"
      :internal-search="false"
      :preserveSearch="false"
      :clearOnSelect="true"
      :show-no-options="true"
      :show-no-results="searchRan"
      :hide-selected="false"
      :options="placeOptions"
      track-by="id"
      label="full_address_en"
      openDirection="bottom"
      selectLabel=""
      deselectLabel="Selected"
      :loading="placeAlternativesLoadingState && isMultiselectOpened()"
      :class="[classes, multiselectClasses]"
      :disabled="disabled"
      @open="searchDefaultOptions"
      @search-change="onSearchChange"
      @select="onPlaceSelect"
      @close="clearPlaceOptions"
    >
      <close-icon
        v-if="value.id && !isMultiselectOpened()"
        slot="caret"
        @mousedown.prevent.stop="clearPlace()"
        class="caret-icon"
      ></close-icon>
      <span slot="noResult" class="input-helper">Place not found</span>
      <span slot="noOptions" class="input-helper">Start typing to search...</span>
      <span slot="singleLabel" slot-scope="props">
        <template>{{ props.option.full_address_en || placeholder || 'All Places' }}</template>
      </span>
      <div slot="afterList" class="loading-after-list" v-if="placeAlternativesLoadingState">
        <span class="input-helper">Searching...</span>
      </div>
    </multiselect>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

export default {
  props: {
    label: {type: String, default: ''},
    value: [Object, String],
    placeholder: {type: String, default: ''},
    error: {type: String, default: ''},
    showDefaultOptions: {type: Boolean, default: false},
    onlyAncestralPlaces: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    id: {type: String, default: 'place'},
    multiselectClasses: {type: String, default: ''},
    labelClasses: {type: String, default: ''},
  },
  data() {
    return {
      searchRan: false,
    };
  },
  computed: {
    ...mapGetters(['placeAlternativesLoadingState', 'placeAlternativesState']),
    classes() {
      return {'has-error': !!this.error};
    },
    placeOptions() {
      return this.placeAlternativesState
        ? this.placeAlternativesState
            .filter(data => data.place_id)
            .map(data => ({
              id: data.place_id,
              full_address_en: data.display_text,
              address_en: data.address_en,
              parents: data.parents,
            }))
        : [];
    },
  },
  methods: {
    onSearchChange(query) {
      this.searchRan = false;
      this.$store.commit('setPlaceAlternativesState', []);
      if (query) {
        this.$store.commit('setPlaceAlternativesLoadingState', true);
        this.searchPlaces(query);
      } else {
        this.searchDefaultOptions();
      }
    },
    searchPlaces: debounce(function (query) {
      this.$store
        .dispatch('searchPlaceAlternativesAction', {query, onlyAncestralPlaces: this.onlyAncestralPlaces})
        .then(() => {
          this.searchRan = true;
        });
    }, 300),
    searchDefaultOptions() {
      if (!this.showDefaultOptions) {
        return;
      }
      this.searchRan = false;
      this.$store.dispatch('getCommonPlaceAlternativesAction').then(() => {
        this.searchRan = true;
      });
    },
    onPlaceSelect(value) {
      this.$emit('select', value);
    },
    clearPlace() {
      this.onPlaceSelect({});
    },
    clearPlaceOptions() {
      this.$store.commit('setPlaceAlternativesState', []);
    },
    isMultiselectOpened() {
      return this.$refs['place-input'] && this.$refs['place-input'].isOpen;
    },
  },
  components: {Multiselect, CloseIcon},
};
</script>

<style lang="scss" scoped>
.place-input {
  min-width: 0;
}
</style>
