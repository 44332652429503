<template>
  <div class="section-zupus">
    <h5>Family Records in {{ pinyin }}</h5>
    <img
      class="image lazyload"
      width="800"
      height="220"
      :data-src="$getAsset('/assets/services/zupus/zupu-preview-optimized.png')"
    />
    <div class="bold">Family Tree Records (Zupus)</div>
    <p>
      Zupus are private documents compiled by families to record the names and details of ancestors. They can include
      several hundreds of years of family history, including: dates, names, accomplishments, but also surname origins,
      and migration histories.
    </p>
    <p>
      <span v-if="loading">Loading...</span>
      <template v-else>
        <span v-if="!count" class="supplemental"
          >No {{ pinyin }} family tree records currently in our database.<br
        /></span>
        <router-link rel="nofollow" :to="zupusSearch">{{ linkText }}</router-link>
      </template>
    </p>
  </div>
</template>

<script>
import {TAB_ID_SOURCES} from '@common/utils/consts.search';

export default {
  props: {
    pinyin: String,
    placeId: [Number, String],
    count: [Number, String],
    loading: Boolean,
  },
  computed: {
    zupusSearch() {
      const query = this.count ? {ancestral_place_id: this.placeId, tab: TAB_ID_SOURCES} : {};
      return {name: 'search-tool-zupu', query};
    },
    linkText() {
      if (!this.count) {
        return 'Browse all family tree records';
      }
      const records = this.count === 1 ? 'record' : 'records';
      return `Browse ${this.count} family tree ${records} from  ${this.pinyin}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-zupus {
  word-break: break-word;
}
.bold {
  margin-top: 16px;
}
</style>
