export default {
  address_en: '{{ address_en }}',
  aliases: ['{{ item }}'],
  ancestors: [
    {
      id: '{{ ancestor.id }}',
      level: '{{ ancestor.level }}',
      name: '{{ ancestor.name }}',
      pinyin: '{{ ancestor.pinyin }}',
      level_name: '{{ ancestor.level_name }}',
    },
  ],
  clans: [
    {
      name_ch: '{{ item.name_ch }}',
      pinyin: '{{ item.pinyin }}',
    },
  ],
  description: '{{ description|safe }}',
  dialects: ['{{ item }}'],
  full_address_ch: '{{ full_address_ch }}',
  full_address_en: '{{ full_address_en }}',
  id: '{{ id }}',
  is_qiao_xiang: '{{ is_qiao_xiang }}',
  level: '{{ level }}',
  migration_places: [
    {
      id: '{{ item.id }}',
      pinyin: '{{ item.pinyin }}',
    },
  ],
  migration_meta: {
    description: '{{ migration_meta.description|safe }}',
    origin_description: '{{ migration_meta.origin_description|safe }}',
    est_population: '{{ migration_meta.est_population }}',
  },
  name: '{{ name }}',
  next_level: '{{ next_level }}',
  pinyin: '{{ pinyin }}',
};
