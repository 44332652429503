<template>
  <div class="stand-out-block-image">
    <div class="image-wrapper">
      <div class="label" v-if="label">
        <span>{{ label }}</span>
      </div>
      <img width="400" height="200" :src="imgSrc" />
    </div>
    <div class="description">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    imgSrc: String,
  },
  name: 'StandOutBlockImage',
};
</script>

<style scoped lang="scss">
.stand-out-block-image {
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: $background-light;

    .label {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $tan-600;
      span {
        margin: 8px 12px;
      }
    }
  }

  .description {
    background-color: $background-light;
    padding: 12px;
    padding-bottom: 8px;
    border-top: 1px solid $neutral-200;
    flex-grow: 1;
  }
}
</style>
